import { TextField, Typography, Button } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import './LoginScreen.css';
import * as Yup from 'yup';
import API from '../API';
import { Redirect } from 'react-router-dom';

export default class LoginScreen extends React.Component {


    constructor(props) {
        super(props);

        this.state = {loaded: false};
        

        
    }


    async load() {
        var token = await localStorage.getItem("bearerToken");
        
        if(!token)
            this.setState({loaded: true, redirect: undefined});
        else {
            API.fetch("token", {token}).then((e) => {
                
                if(API.user) this.redirect();
                else this.setState({loaded: true});
            })
        }
        
    }
    componentDidMount() {
        this.load();
    }

    
    redirect() {
        if(this.props.location.state.redirect) {
            this.props.history.replace(this.props.location.state.redirect)
        } else {
            this.props.history.replace("/");
        }
        this.setState({loaded: true, redirect: (this.props.location.state.redirect || "/")});
    }
    render() {
        if(!this.state.loaded) return <></>

        if(this.state.redirect != undefined)
            return <Redirect push={false} to={this.state.redirect} />

        return <div style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100vh",backgroundColor:"#F9FAFC", paddingLeft:"24px", paddingRight:"24px"}}>
        
            <Formik innerRef={(a) => this.formikRef = a} onSubmit={(values) => {
                API.fetch('login', values).then((e) => {
                    if(e.error)
                        this.formikRef.setSubmitting(false);
                    else this.redirect();
                    
                })
            }} initialValues={{
      email: '',
      password: ''
    }} validationSchema={Yup.object({
      email: Yup
        .string()
        .email(
          'Must be a valid email')
        .max(255)
        .required(
          'Email is required'),
      password: Yup
        .string()
        .max(255)
        .required(
          'Password is required')
    })}>
                {formik => <form className='loginForm' onSubmit={formik.handleSubmit}>
                    <Typography style={{fontWeight:"700", textAlign:"center", marginBottom:24, color:"rgb(18, 24, 40)"}} variant='h4'>Giriş yap</Typography>
                    <TextField 
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     helperText={formik.touched.email && formik.errors.email}
                     error={Boolean(formik.touched.email && formik.errors.email)}
                     value={formik.values.email}
                     name='email'
                     type={'email'}
                     className='loginFormInput' label='E-posta' variant='outlined' />
                    <TextField
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.password && formik.errors.password}
                    error={Boolean(formik.touched.password && formik.errors.password)}
                    value={formik.values.password}
                    name='password'
                    type={'password'}
                    className='loginFormInput' style={{marginTop:24}} label='Şifre' variant='outlined' />

<Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{marginTop:24}}
                onSubmit={formik.handleSubmit}
              >
                Sign In Now
              </Button>
                </form>}


            </Formik>
        </div>
    }
}
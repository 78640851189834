import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Link, Tooltip, TextareaAutosize, colors, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorIcon from '@mui/icons-material/Error';
import { Document } from 'react-pdf';
import Rating from '@mui/material/Rating';
import WhatsappIcon from '../whatsapp.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Image } from '@mui/icons-material';
import Review from '../components/Review';
import ForumIcon from '@mui/icons-material/Forum';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OrderIDTableCell from '../components/OrderIDTableCell';
import { MuiTelInput } from 'mui-tel-input'
// const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet", "Esnek"];

const fileNames = ['Sürücü Belgesi Ön',"Sürücü Belgesi Arka", "İkametgah Belgesi","Adli Sicil Kaydı","Profil Fotoğrafı",'Vergi Levhası'];
const optionalFileNames = ['K Sertifikası', 'Psikoteknik Sertifikası', 'SRC Sertifikası']

var IN_EDIT_MODE = false;

var STATUS = {};
STATUS[1] = {
    t: "Planlanmış",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[3] = {
    t: "Tarih Kesinleş.",
    color: "rgb(80,72,229)"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "yellow"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}
export default class DriverScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, approveDriverDialog: false, backdropVisible: false, vehiclePromptVisible: false, vehiclePromptFile: undefined, vehiclePromptPlate: "", vehiclePromptType: 0};

        
        var id = props.match.params.id;

        
        if(!id) props.history.goBack();

        
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        
        API.fetch("getdriver", {_id: id}).then((e) => {
           
            if(e.error) return this.props.history.goBack();
            else {
                var c = 0;
                e.orders.forEach((order) => {
                    if(order.driver != e._id && order.status != -1) c++;
                })

                e.canceledOrderCount = c;

                if(e.invoiceDetails == undefined) e.invoiceDetails = {};
            }


            this.setState({driver: e, loading: false});
        })
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }
    render() {
        IN_EDIT_MODE = this.state.editing;


        if(this.state.loading) return <></>
        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div style={{display:"flex", position:"relative"}}>

                {/* <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 800 800",
      position:"absolute",
      width:100,
      zIndex: 1,
      top:-55,
      left:-10
    }}
    viewBox="0 0 800 800"

    
  >
    
    <path
      d="M401.602 769.448c-2.432.533-4.934.689-7.417.58-1.68-.078-3.361-.24-4.999-.605-1.164-.237-2.306-.576-3.455-.905.186 1.464.778 2.863 1.622 4.072.997 1.403 2.332 2.687 3.988 3.208 1.29.355 2.831.794 5.493.747 2.196-.033 4.353-.315 6.277-1.142a12.157 12.157 0 0 0 3.411-2.174 8.715 8.715 0 0 0 2.008-2.926c.453-1.036.863-2.887.819-3.842-2.377 1.356-5.033 2.534-7.747 2.987z"
      style={{
        fill: "#312a20",
      }}
    />
    <path d="M203.088 436.81s121.289-218.016 234.086-213.367c52.271 2.155 80.667 54.58 189.018 187.39 23.68 29.025-73.957.844-99.832-13.711 0 0 29.678 71.323 17.55 81.504-22.654 19.016-355.376-7.855-340.822-41.816z" />
    <path
      d="M214.989 431.339s117.473-205.47 219.314-200.9c52.262 2.345 64.324 43.946 183.472 178.285 24.855 28.025-78.717-9.817-104.593-24.371 0 0 33.153 80.05 21.025 90.231-22.654 19.016-333.773-9.285-319.218-43.245z"
      style={{
        fill: "red",
      }}
    />
    <path d="M533.792 394.077c-23.439-14.475-31.93-28.12-58.411-67.336 0 0 39.928 64.925 46.647 80.675 6.72 15.75-3.59-15.027.655-15.027s13.499 3.163 11.109 1.688z" />
    <path
      d="M475.381 326.74s89.802 82.597 124.642 83.022c0 0-3.117 4.079-3.667 5.668-.417 1.205-47.293-3.131-79.019-31.988-13.127-11.94-32.485-42.67-41.956-56.702z"
      style={{
        fill: "#ba0808",
      }}
    />
    <path d="M460.028 332.805s24.459 12.937 54.497 60.486c10.871 17.208-17.505-45.527-54.497-60.486zM556.344 493.337c-.495-6.746-.266-11.763-17.707-26.07-18.356-9.955-32.218-13.512-43.255-14.848-3.575-.434 18.395-3.008 15.385-3.985-48.516-15.768-95.818-12.332-95.818-12.332l17.284-10.511c-28.604-7.885-89.09-6.377-115.904-2.459l19.429-9.216c-38.666-1.87-84.902 3.968-107.871 15.085-2.298 1.113 1.069-6.262 1.069-6.262l-16.761 4.67c-18.54 6.636-26.521 14.498-27.281 33.827l-.266 30.723c-.547 9.507 11.494 17.166 27.468 13.458 0 0 38.529-8.045 155.166.17 110.185 7.761 149.423 34.535 149.423 34.535 18.271 8.081 28.894-1.364 35.337-19.125l4.302-27.66z" />
    <path
      d="M528.875 536.977c-2.731 0-5.852-.742-9.28-2.21-2.893-1.851-11.365-8.533-40.852-16.815-2.076-.583 6.857-1.092 6.857-1.092-17.284-2.426-53.776-10.246-77.602-13.34-5.678-.736 14.43-2.911 8.247-3.54-7.153-.728-40.685.111-48.537-.442-31.446-2.215-80.92.038-99.922-9.867-2.489-1.299 9.185 6.156 6.465 6.156-24.087 0-40.298.677-50.245 1.937-9.512 1.206-13.959-3.055-13.428-2.129 2.351 4.093.586 3.785.298 3.846l-.132.029c-2.276.528-4.515.796-6.654.796-4.98 0-9.374-1.504-11.753-4.024-1.165-1.234-1.715-2.57-1.634-3.974l.01-.297.264-30.629c.62-15.518 8.887-24.977 30.719-31.243l-3.841 7.378c23.752-10.107 37.347-19.779 95.009-19.811-.869 1.197-13.039 11.432-13.039 11.432s49.021-6.378 118.219.293c-.345.98-10.979 8.693-10.979 8.693s27.088 1.414 30.727 1.414c18.132 0 40.48 4.552 55.287 9.047-5.862.758-8.545 3.736-8.667 4.067 0 0 7.456 1.579 11.333 2.54 13.608 3.373 54.946 16.387 54.501 37.883l-4.103 26.396c-6.491 17.443-14.537 17.506-17.238 17.506h-.03z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M542.38 527.54c-5.555 9.388-11.281 9.437-13.476 9.437h-.037c-2.729 0-5.845-.752-9.277-2.22-2.888-1.843-11.365-8.525-40.85-16.809-2.074-.583 6.865-1.092 6.865-1.092-17.296-2.426-53.779-10.249-77.613-13.342-5.676-.74 14.434-2.912 8.248-3.542-7.156-.728-40.68.109-48.528-.437-31.45-2.22-80.923.036-99.929-9.873-2.486-1.298 9.182 6.162 6.465 6.162-24.088 0-40.292.679-50.238 1.94-9.521 1.201-13.96-3.056-13.438-2.134 2.352 4.087.594 3.784.303 3.845l-.134.036c-2.28.521-4.512.788-6.646.788-4.985 0-9.376-1.503-11.753-4.026-1.176-1.226-1.722-2.571-1.637-3.967l.012-.303.255-30.625c.437-10.71 4.499-18.521 14.143-24.404-3.026 2.767-10.606 9.034-9.595 20.151l.379 24.713c.48 3.79 2.412 6.049 3.714 7.126 3.942 3.259 9.092 2.444 14.077 2.444 2.134 0 4.378-.267 6.646-.788l.134-.036c.291-.061 2.049.242-.291-3.845-.534-.922 3.917 3.335 13.427 2.134 9.945-1.261 26.149-1.939 50.238-1.939 2.729 0-8.951-7.46-6.465-6.162 19.006 9.909 68.479 7.653 99.929 9.873 7.847.546 44.637 3.15 51.793 3.878 6.174.63-11.942 4.718-6.267 5.458 23.833 3.093 57.562 7.328 74.845 9.754 0 0-9.502 1.843-7.429 2.426 29.498 8.284 36.048 11.863 38.947 13.706 3.42 1.468 6.537 2.22 9.278 2.22h.024c.836-.001 2.206-.001 3.881-.547z"
      style={{
        fill: "#e5e5e5",
      }}
    />
    <path d="M605.095 470.902c-5.431-4.046-36.53-20.999-24.974-55.587 3.386-10.132 13.794-22.931 20.729-28.708.821-.684-5.086 14.443-.303 10.613 15.538-12.441 39.81-23.444 55.914-19.135 1.367.365-11.646 7.652-10.29 8.143 1.887.684 26.38 8.642 35.988 29.462 0 0-5.607-4.122-8.688.633-1.833 2.83 6.723 11.623 2.112 34.466-2.233 11.067-12.674 28.604-23.56 30.354 0 0 .815-9.578-1.444-8.624-26.237 11.081-51.548 7.984-53.772 4.549-.233-.36 11.297-3.921 8.288-6.166z" />
    <path
      d="M608.822 474.511c1.157.009 3.928-1.543 2.063-3.746-2.355-2.785-36.885-17.396-24.813-53.705 1.679-5.053 8.041-16.109 7.688-13.904-.38 2.38.308 9.543 4.334 4.48 9.447-11.881 31.036-24.835 44.784-24.465.031.349-4.611 2.905-3.173 5.335 1.016 1.72 22.305 4.403 37.19 22.769-4.756-2.631-8.386 1.096-8.268 2.578.654 8.168 6.186 15.753 2.858 32.772-1.774 9.07-7.771 21.953-13.181 25.268-.985-1.39.256-6.953-4.077-10.876-.884-.799-.013 1.927-4.843 5.596-17.878 13.579-39.738 8.615-40.562 7.898z"
      style={{
        fill: "#fff",
      }}
    />
  </svg> */}
                    <Avatar sx={{width:70, height:70}} src={"https://ik.imagekit.io/tasimacim/driver/tr:w-auto/"+this.state.driver._id+".jpeg"} /> {/*https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/*/}
                    <div style={{marginLeft:20}}>
                        
                        <div style={{display:"flex", alignItems:"center", gap: 10}}><Typography variant='h4'>{this.state.driver.name}</Typography><Tooltip title={this.state.driver.reviews.length + " değerlendirmede " + (this.state.driver.review || 0).toFixed(2) + " yıldız"}><div style={{display:"flex", alignItems:"center"}}><Rating precision={0.01} readOnly  value={this.state.driver.review} /><Typography variant='body1'>({this.state.driver.reviews.length})</Typography></div></Tooltip></div>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>partner_id: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{this.state.driver._id}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                <div style={{display:"flex",marginBottom:5,justifyContent:"space-evenly",alignItems:"center"}}>
                <a href="#" style={{fill: "#3c9cd7"}} onClick={() => {
            window.open('../livesupport/'+this.state.driver._id,'popup','width=400,height=600')
        }}><ForumIcon  htmlColor={'#3c9cd7'} /></a>
                <a href={"https://wa.me/"+(this.state.driver.phone)} target={'_blank'}><img style={{width:25,height:25,marginRight:18}} src={WhatsappIcon} /></a>
                </div>
                    {!this.state.editing && <Button onClick={() => {

                        this.driverOld = JSON.parse(JSON.stringify(this.state.driver));
                        this.setState({editing: true});
                    }} style={{marginRight:20}} variant='outlined'>Düzenle <EditIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    
                    
                    {this.state.editing && <Button onClick={() => {

                        
                        this.setState({editing: false, driver: this.driverOld});
                    }} style={{marginRight:20}} color='error' variant='outlined' >İptal <CloseIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    {/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}
                </div>



                
            </div>

                <div style={{display:"flex",marginTop:24}}>
                    <LogisticAnalytic backgroundColor={'rgba(80,72,229,.1)'} first title={"Son siparişler"} value={this.state.driver.orders.length} icon={<LocalShippingIcon sx={{color: 'rgb(80,72,229)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(134,197,82,.1)"} title={"Güncel bakiye"} value={(this.state.driver.credits+this.state.driver.freeCredits).toFixed(2) +(this.state.driver.freeCredits > 0?" ("+this.state.driver.credits.toFixed(2)+")":"")} icon={<AccountBalanceWalletIcon sx={{color: 'rgb(134,197,82)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(255,176,32,.1)"} title={"7 günde iptal"} value={(this.state.driver.canceledOrderCount)} icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} />
                
                    
                </div>

<Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Detaylar</Typography>
                
                <DetailText first label={"Email"} onChange={(e) => {this.state.driver.email = e; this.forceUpdate()}} value={this.state.driver.email} />
                <DetailText label={"T.C Kimlik"} onChange={(e) => {this.state.driver.tc = e; this.forceUpdate()}} value={this.state.driver.tc} />
                <DetailText label={"VKN"} onChange={(e) => {this.state.driver.vkn = e; this.forceUpdate()}} value={this.state.driver.vkn} />
               
                <DetailText editOutside label={"Telefon"} onCopy={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(document.getSelection().toString().replace(/ /g, ''));

                }} customEdit={() => <div style={{display:"flex",flex:1}}><MuiTelInput  style={{width: "fit-content"}} forceCallingCode  defaultCountry='TR' value={this.state.driver.phone} onChange={(e) => {
                                        
                
                    this.state.driver.phone = e.replaceAll(/ /g, '');
                    this.forceUpdate();
                }} disableDropdown onlyCountries={['TR']} /></div>} onChange={(e) => {this.state.driver.phone = e; this.forceUpdate()}}  value={`+90 ${this.state.driver.phone.slice(3, 6)} ${this.state.driver.phone.slice(6, 9)} ${this.state.driver.phone.slice(9)}`} />
                <DetailText editable={false} label={"Kayıt Tarihi"} value={dateFromObjectId(this.state.driver._id).toLocaleString("tr-TR")} />

                <DetailText editable={false} label={"İl"} value={API.getCityName(this.state.driver.city)} />
                <DetailText label={"Not (Gizli)"} customDisplay={() => <TextareaAutosize value={this.state.driver.note} minRows={1} style={{width:"100%"}} disabled  />} value={this.state.driver.note || ""} customEdit={() => <TextareaAutosize value={this.state.driver.note} minRows={1} style={{width:"100%"}} onChange={(e) => {this.state.driver.note = e.currentTarget.value; this.forceUpdate()}}  />} />

                </div>
            
            </Badge>

            {this.state.driver.files != undefined && <div style={{backgroundColor:"#EDF7ED",display:"flex", columnGap: 20, flexWrap:"wrap", justifyContent:"left", padding:15,paddingLeft:20, paddingRight:20, borderRadius:5, marginTop:10}}>
                {console.log(this.state.driver.files)}
                {/* {fileNames.filter((s,i) => this.state.driver.files[i] == undefined).map((x, i) => <DriverFileStatus check={false} key={i} name={fileNames[x]} />)} */}
                {Object.keys(this.state.driver.files).map((docId, i) => <DriverFileStatus check={this.state.driver.files[docId] != undefined} key={i} name={(docId >= 1000)?optionalFileNames[docId-1000]:fileNames[docId]} />)}
                
            </div>}


            <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                    <div style={{display:"flex",flex:1, justifyContent:"space-between",paddingRight:24}}>
                    <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Fatura Detayları</Typography>
 <div style={{display:"flex",alignItems:"center",gap: 10}}>
            {this.state.driver.invoiceDetails?.vkn == undefined && <div style={{color:"white", backgroundColor:"orange", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Doldurulmadı</Typography></div>}
            {this.state.driver.invoiceDetails?.vkn != undefined && this.state.driver.invoiceDetails?.verified != true && <div style={{color:"white", backgroundColor:"orange", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Onay Bekliyor</Typography></div>}
            {this.state.driver.invoiceDetails?.vkn != undefined && this.state.driver.invoiceDetails?.verified != true && <Button variant='contained'  component="label" color='success' onClick={() => {
                     global.openPrompt("Fatura detaylarını onaylamak istediğinize emin misiniz?","","Onayla", () => {
                        global.showLoadingPanel();
                        API.fetch("approvedriverdetails", {id: this.state.driver._id}).then(e => {
                            location.reload();
                        })
                    })
                }}>Onayla</Button>}

            {this.state.driver.invoiceDetails?.verified == true && <div style={{color:"white", backgroundColor:"green", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Onaylandı</Typography></div>}
                {/* {this.state.order.invoiceDetails.invoiceUrl == undefined && <div style={{color:"white", backgroundColor:"crimson", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Fatura Yüklenmedi</Typography></div>}
                {this.state.order.invoiceDetails.invoiceUrl != undefined && this.state.order.invoiceDetails.status != 10 && <div style={{color:"white", backgroundColor:"crimson", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>ONAY BEKLIYOR</Typography></div>}
                {this.state.order.invoiceDetails?.status == 1 && <Button variant='contained'  component="label" color='success' onClick={() => {
                     global.openPrompt("Faturayı onaylamak istediğinize emin misiniz?","Bu işlem onaylandıktan sonra yüklenmiş olan fatura müşteriye iletilecek (Taşımacım'a değil ise) ve Cari ise sürücüye bakiyesi otomatik olarak yatırılacak","Onayla", () => {
                        global.showLoadingPanel();
                        API.fetch("approveinvoice", {id: this.state.order._id}).then(e => {
                            location.reload();
                        })
                    })
                }}>Onayla</Button>} */}
                </div>

                    </div>
             
                
                <DetailText customEdit={() =>  <RadioGroup
                style={{display:"flex",flexDirection:"row"}}
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={this.state.driver.invoiceDetails.type}
    onChange={(change) => {
        this.state.driver.invoiceDetails.type = change.target.value;
        this.forceUpdate();
    }}
  >
    <FormControlLabel label="Şahıs Şirketi" control={<Radio />} value={0} />
    <FormControlLabel label="Limited/Anonim Şirketi" control={<Radio />} value={1} />
  </RadioGroup>}  first label={"Şirket Tipi"} onChange={(e) => {this.state.driver.invoiceDetails.type = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.type == 0?"Şahıs Şirketi":this.state.driver.invoiceDetails.type == 1?"Limited/Anonim Şirketi":"-"} />
                <DetailText label={"Ünvan"} onChange={(e) => {this.state.driver.invoiceDetails.name = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.name || ""} />
                <DetailText label={"VKN"} onChange={(e) => {this.state.driver.invoiceDetails.vkn = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.vkn || ""} />
                <DetailText label={"Adres"} onChange={(e) => {this.state.driver.invoiceDetails.address = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.address || ""} />
                <DetailText label={"Vergi Dairesi"} onChange={(e) => {this.state.driver.invoiceDetails.department = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.department || ""} />
                <DetailText label={"IBAN Ünvan"} onChange={(e) => {this.state.driver.invoiceDetails.ibanName = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.ibanName || ""} />
                <DetailText label={"IBAN"} onChange={(e) => {this.state.driver.iban = e; this.forceUpdate()}} value={this.state.driver.iban} />


                    
                {this.state.driver.invoiceDetails?.imageURL != undefined && <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10,paddingRight:24, paddingBottom:10,maxHeight:500, borderBottom:"solid 1px #E6E8F0", borderTop:("")}}>
        
        <div style={{display:"flex",flex:1}}>
        <img style={{width:"100%", objectFit:"contain", maxHeight:480}} src={this.state.driver.invoiceDetails.imageURL} />
                
                {/* 
        
        
                {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
                {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
                {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}
        
        </div>
            </div>}
                </div>
            
            </Badge>

            {this.state.driver.loc != undefined && <div style={{height:200, marginTop:30, borderRadius:8, overflow:"hidden"}}>
            <MapContainer center={[this.state.driver.loc.coordinates[1],this.state.driver.loc.coordinates[0]]} style={{width:"100%",height:"100%"}} zoom={10.5} scrollWheelZoom={true}>
                <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
<Marker position={[this.state.driver.loc.coordinates[1],this.state.driver.loc.coordinates[0]]} />



  
  {/* {this.state.order.waypoints.map((x, i) => <Marker key={i} position={[x.loc.coordinates[1], x.loc.coordinates[0]]}>
    <Popup>
      {x.name} • {x.vehicles.map((x) => types[x.type]).join(",")} • {(x.credits+x.freeCredits).toFixed(2)}₺
      <br/>
      {new Date(x.loc.time).toLocaleString()}
      {console.log(x)}
    <br></br>
      <a href={'../driver/'+x._id}>{x._id}</a>
    </Popup>
  </Marker>)} */}

  
                </MapContainer>

                </div>}

                {this.state.driver.loc != undefined && <p style={{textAlign:"center", marginTop:2}}>Sürücü lokasyonu son güncelleme: {new Date(this.state.driver.loc.time).toLocaleString('tr-TR')}</p>}


            

            {this.state.driver.vehicles && this.state.driver.vehicles.map((vehicle, i) => <Badge style={{marginTop:24, padding:0, paddingBottom:24}} key={i}>
                
                <div style={{display:"flex",flexDirection:"row",paddingRight:20, justifyContent:"space-between",alignItems:"center"}}><Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>ARAÇ #{i}</Typography>
                {vehicle.active != false && IN_EDIT_MODE && <Button onClick={() => {
                    global.openPrompt("Aracı deaktif etmek istediğinize emin misiniz?","","Onayla", () => {
                        API.fetch("deactivatevehicle", {_id: this.state.driver._id,vehicle: vehicle._id}).then((e) => {
                            vehicle.active = false;
                            this.forceUpdate();
                        })
                    })
}} style={{margin:0,height:"fit-content"}} color='error' variant='outlined' >Deaktif Et</Button>}
                </div>

                {vehicle.active == false && <Typography style={{color:"crimson"}}>Bu araç deaktif edilmiştir ve sürücüde gözükmez</Typography>}
               

                <DetailText onChange={(e) => {vehicle.plate = e; this.forceUpdate()}} first label={"Plaka"} value={vehicle.plate} />
                <DetailText customEdit={() => <Select onChange={(e) => {vehicle.type = e.target.value; this.forceUpdate()}} value={vehicle.type} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}} style={{position:"absolute", left:"20%"}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>} label={"Araç Tipi"} value={VEHICLE_NAMES[vehicle.type]} />

                <DetailText onChange={(e) => {vehicle.innerWidth = e; this.forceUpdate()}}  label={"Genişlik (cm)"} value={vehicle.innerWidth || ""} />
                <DetailText onChange={(e) => {vehicle.innerHeight = e; this.forceUpdate()}}  label={"Yükseklik (cm)"} value={vehicle.innerHeight || ""} />
                <DetailText onChange={(e) => {vehicle.innerDepth = e; this.forceUpdate()}}  label={"Uzunluk (cm)"} value={vehicle.innerDepth || ""} />
                
                {/* <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>Ruhsat</Typography>
                {vehicle.ruhsat && <FilePreview style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />}

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Ön</Typography>
                {vehicle.front && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />}
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Yan</Typography>
                {vehicle.side && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />} */}


                {/* <FilePreview onClick={() => {vehicle.ruhsat = {removed: true}; this.forceUpdate()}} title={'Ruhsat'} style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview onClick={() => {vehicle.front = {removed: true}; this.forceUpdate()}} title={'Araç Ön'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview onClick={() => {vehicle.side = {removed: true}; this.forceUpdate()}} title={'Araç Yan'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />
                    </div>
                </div> */}
            </Badge>)}

            {this.state.editing && <Button onClick={() => {this.setState({vehiclePromptVisible: true})}} style={{marginTop:10}}>Araç Ekle</Button>}


            {IN_EDIT_MODE && <div style={{display:"flex", justifyContent:"flex-end", marginTop:24, alignItems:"center"}}>
                
                {!this.state.saving && <Button onClick={() => {
                    this.setState({saving: true});

                    this.state.driver.vehicles.forEach(vehicle => {
                        if(vehicle.innerWidth && typeof(vehicle.innerWidth) != 'number') {
                            try {
                                vehicle.innerWidth = parseInt(vehicle.innerWidth);
                            } catch {}
                            if(isNaN(vehicle.innerWidth)) vehicle.innerWidth = undefined;
                        }

                        if(vehicle.innerHeight && typeof(vehicle.innerHeight) != 'number') {
                            try {
                                vehicle.innerHeight = parseInt(vehicle.innerHeight);
                            } catch {}
                            if(isNaN(vehicle.innerHeight)) vehicle.innerHeight = undefined;
                        }

                        if(vehicle.innerDepth && typeof(vehicle.innerDepth) != 'number') {
                            try {
                                vehicle.innerDepth = parseInt(vehicle.innerDepth);
                            } catch {}
                            if(isNaN(vehicle.innerDepth)) vehicle.innerDepth = undefined;
                        }
                    })

                    
                   
                    API.fetch("savedriver", this.state.driver).then((e) => {
                        this.setState({saving: false, editing: false});
                    })
                }} variant='contained' color='success' size='medium'>Kaydet</Button>}
                {this.state.saving && <CircularProgress />}
            </div>}


            <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son Siparişler</Typography>
                
                </div>
            <DataGrid
                autoHeight
                
                rows={this.state.driver.orders.map((x,i) => ({id:i,order:x,Tarih: dateFromObjectId(x._id).toLocaleString("tr-TR"),Araç: VEHICLE_NAMES[x.vehicle],Durum: (x.status == -1 && x.driver == this.state.driver._id)?11:(x.driver == this.state.driver._id)?x.status:12}))}
                columns={[{field: "ID", renderCell:(params) => <OrderIDTableCell order={params.row.order} />,flex: 1}, {field: "Müşteri",flex: 1,renderCell: (params) => <RouterLink to={'/client/'+params.row.order.clientId}><Link>{params.row.order.clientId.substring(18).toUpperCase()}</Link></RouterLink>},{field: "Tarih",flex: 1},{field:"Araç", flex:1}, {field: "Durum",flex: 1, renderCell: (params) => <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:STATUS[params.value].color}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{STATUS[params.value].t}</Typography>
                </div>},{field: " ", renderCell: (params) => {
                    return <RouterLink to={'/order/' + params.row.order._id} ><Button><ArrowForwardIcon /></Button></RouterLink>
                }}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>

            {API.user.permissions.includes(0) && <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son İşlemler</Typography>
                
                </div>
                <DataGrid
            getRowId={(row) => row._id}
                autoHeight
                rows={this.state.driver.transactions}
                columns={[{field: "Tarih",flex:0.4, valueGetter: (params,row) => new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")},{field: "Tür",flex:1, valueGetter: (params,row) => {
                    
                    return API.getTransactionDescription(row)
                }},{field: "Tutar", flex:0, valueGetter: (params,row) => row.value.toFixed(2)+ "₺"}]}
                pageSize={10}
                getCellClassName={(param) => param.row.status == -1?"cellTransactionCancelled":""}
                rowsPerPageOptions={[10]}
                initialState={{pagination: {paginationModel: {pageSize: 50, page: 0}}}}
                onPaginationModelChange={(model) => {
                  API.fetch('getdrivertransactions', {_id: this.state.driver._id, page: model.page}).then(e => {
                    this.state.driver.transactions = e;
                    this.forceUpdate();
                  })
                }}
                rowCount={this.state.driver.transactionCount}
                paginationMode='server'
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>}

            

            {this.state.driver.reviews.filter(s => s.review.message != undefined && s.review.message != "").map((x, i) => <Review key={i} review={{_id: x._id,...x.review}} />)}

    
            <div style={{marginTop:20}}></div>
            <a target='_blank' style={{color: '#267FCA', marginTop:50}} href={"https://eu-central-1.console.aws.amazon.com/s3/buckets/tasimacim-partners?region=eu-central-1&bucketType=general&prefix="+this.state.driver._id+"/&showversions=false"}>AWS'ye Git <OpenInNewIcon /></a>




            
        </div>



        <Dialog
        open={this.state.vehiclePromptVisible}
        onClose={() => {
            this.setState({prompt: undefined});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Yeni araç kaydet
        </DialogTitle>
        <DialogContent style={{gap: 10, display:"flex", flexDirection:"column"}}>
          

          <div>

          <TextField value={this.state.vehiclePromptPlate} onChange={(e) => this.setState({vehiclePromptPlate: e.currentTarget.value})} label="Plaka" />
           
          </div>
           <Select onChange={(e) => {this.setState({vehiclePromptType: e.target.value})}} value={this.state.vehiclePromptType} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>
                {this.state.vehiclePromptFile != undefined && <img style={{width:250, height:250, objectFit:"contain"}}  src={URL.createObjectURL(this.state.vehiclePromptFile)} />}
                <Button component="label" variant="contained">

                    
  {this.state.vehiclePromptFile != undefined?"Başka bir ruhsat yükle":"Ruhsat yükle"}
  <input onChange={(e) => {
    this.setState({vehiclePromptFile: e.target.files[0]})
    console.log(e.target.files);
  }} style={{display: "none"}} multiple={false} accept="image/png, image/gif, image/jpeg"  type="file" />
</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({vehiclePromptVisible: false})}>İptal</Button>
          <Button onClick={() => {
        
        global.showLoadingPanel();
        this.setState({vehiclePromptVisible: false})

            if(this.state.vehiclePromptFile == undefined) return;

        
            API.fetch('addvehicle', {file: this.state.vehiclePromptFile.name,driver: this.state.driver._id, type: this.state.vehiclePromptType, plate: this.state.vehiclePromptPlate}).then(async e => {
                
                if(e.error) return;

                let formData = new FormData()
                formData.append('file', this.state.vehiclePromptFile);
                
                
                const response = await fetch(e.v, {method: "PUT",body:this.state.vehiclePromptFile,headers: {
                        "Content-Type":"multipart/form-data"
                }});

                

                
            
                
                //global.hideLoadingPanel();

                //console.log(response);
                location.reload();
            })

          }} autoFocus>
            Ekle
          </Button>
        </DialogActions>
      </Dialog>
        

        

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }
}

const DriverFileStatus = (props) => 
    <div style={{display:"flex",alignItems:"center", gap: 5,width:200}}> 
    {props.check == true && <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={15}
    height={15}
    viewBox="0 0 17.837 17.837"
    {...props}
  >
    <path
      d="M16.145 2.571a.7.7 0 0 0-.99 0L6.92 10.804l-4.241-4.27a.698.698 0 0 0-.989 0L.204 8.019a.703.703 0 0 0 0 .99l6.217 6.258a.704.704 0 0 0 .99 0L17.63 5.047a.7.7 0 0 0 0-.994l-1.485-1.482z"
      style={{
        fill: "rgb(30, 70, 32)",
      }}
    />
  </svg>}
  {props.check == false && <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24' width={15} height={15} {...props}>
    <path fill='crimson' d="m24 20.188-8.315-8.209 8.2-8.282L20.188 0l-8.212 8.318L3.666.115 0 3.781l8.321 8.24-8.206 8.313L3.781 24l8.237-8.318 8.285 8.203z" />
  </svg>}
  <p style={{color:props.check?"rgb(30, 70, 32)":'crimson',fontWeight:"500"}}>{props.name}</p></div>



const LogisticAnalytic = (props) => {
    return <Badge style={{marginLeft: props.first?0:24}}>
        <div style={{display:"flex", alignItems:"center"}}>
            <div style={{backgroundColor: props.backgroundColor, width:40, height:40, borderRadius:8, justifyContent:"center", alignItems:"center", display:"flex"}}>
                {props.icon}
            </div>

            <Typography style={{marginLeft:10}} variant='h5'>{props.value}</Typography>
        </div>
        <Typography style={{marginTop:15,color:"rgb(101, 116, 139)",fontWeight:"400"}} variant='body2'>{props.title}</Typography>
    </Badge>
}

const FilePreview = ({file, urlBase,title, ...rest}) => {
    return <div>

        {!IN_EDIT_MODE && <Typography style={{marginBottom:24, marginLeft:24, marginTop:24,textAlign:"center"}} variant='h6'>{title}</Typography>}
        {IN_EDIT_MODE && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>{title}</Typography>

        {file && !file.removed && <div style={{marginLeft:10}}>
            <Button onClick={() => {
                rest.onClick();
            }} variant='outlined' color='error'>Sil</Button>
        </div>}
        </div>}
        <div style={{display:"flex", justifyContent:"center"}}>
        
        {file && !file.removed && (file.path.endsWith(".png") || file.path.endsWith(".jpeg") || file.path.endsWith(".jpg")) && <img {...rest}  src={urlBase+file.path} />}
        {file && !file.removed && (file.path.endsWith(".pdf")) && <Document file={{url: urlBase+file.path}}>
        {/* <Page pageNumber={pageNumber} /> */}
      </Document>}
        
        {file && file.removed && <Typography variant='h6' color='crimson'>KALDIRILDI</Typography>}
        </div> 
    </div>
}

const DetailText = (props) => {
    return <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10, paddingBottom:10, borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        
<div style={{display:"flex",flex:1}}>
        <div style={{maxWidth:180,flex:.6, display:"flex", alignItems:(props.centerLabel)?"center":undefined}}>
        <Typography style={{fontWeight:"600"}}>{props.label}</Typography>
        </div>
        {(!IN_EDIT_MODE ||props.editOutside != true) && <div className='detailParent' style={{flex:1, alignItems:"center", justifyContent:"center"}}>
        {(!IN_EDIT_MODE || props.editable == false) && props.link == undefined && <Typography {...props}>{props.value}</Typography>}
        {(!IN_EDIT_MODE || props.editable == false) && props.link != undefined && <Link to={props.link} ><Typography {...props}>{props.value}</Typography></Link>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)}  sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.editOutside != true && props.customEdit()}
        {!IN_EDIT_MODE && props.children && {...props.children}}
        </div>}
        {IN_EDIT_MODE && props.customEdit != undefined && props.editOutside == true && props.customEdit()}
        {/* 


        {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}

</div>

        {props.altComponent != undefined && props.altComponent}
    </div>
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}